import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Container,
  ButtonGroup,
  Stack,
  Text,
  Checkbox,
  HStack,
  VStack,
  Link,
  Box,
  SimpleGrid,
  Heading,
  Input,
  Collapse,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from 'react-hook-form';


const SignupForm = ({
  idstr,
  newone,
  forma
}) => {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      const input_name = event.target.name;
      if (input_name === 'time' && newone !== true) {
        form.elements['pr_rekl_pos'].focus();
        event.preventDefault();
      }
      else if (input_name === 'interes_pos' && newone !== true) {
        form.elements['rekom_vip_pos'].focus();
        event.preventDefault();
      }
      else if (input_name === 'interes_pos' && newone === true) {
        form.elements['nalog_bank_pos'].focus();
        event.preventDefault();
      }
      else if (input_name === 'interes_pok') {
        form.elements['rekom_vip_pok'].focus();
        event.preventDefault();
      }
      else if (input_name === 'zak_zas_pos') {
        form.elements['zasor_pos'].focus();
        event.preventDefault();
      }
      else {
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    }
  };

  if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
  } else {
      window.onbeforeunload = function () {
          window.scrollTo(0, 0);
      }
  }

  const { isOpen, onToggle } = useDisclosure("");
  const { onOpen } = useDisclosure({ defaultIsOpen: true });
  const modal1 = useDisclosure();
  const modal2 = useDisclosure();
  const modal3 = useDisclosure();
  const modal4 = useDisclosure();
  const toast = useToast();
  const [name, setName] = useState("");
  const [commer, setCommer] = useState("");
  const [pzy, setPzy] = useState("");
  const [vid_loma, setVid_loma] = useState("");
  const [namen, setNamen] = useState("");
  const [commern, setCommern] = useState("");
  const [pzyn, setPzyn] = useState("");
  const [vid_loman, setVid_loman] = useState("");
  const [pr_rekl_pos, setPr_rekl_pos] = useState("");
  const [interes_pos, setInteres_pos] = useState("");
  const [nalog_bank_pos, setNalog_bank_pos] = useState("");
  const [zasor_pos, setZasor_pos] = useState("");
  const [zak_zas_pos, setZak_zas_pos] = useState("");
  const [zak_zas, setZak_zas] = useState("");
  const [dop_zas_pos, setDop_zas_pos] = useState("");
  const [rekom_vip_pos, setRekom_vip_pos] = useState("");
  const [stavka_mlz_pos, setStavka_mlz_pos] = useState("");
  const [vip_doh_pos_2, setVip_doh_pos_2] = useState("");
  const [stavka_mlz_pos2, setStavka_mlz_pos2] = useState("");
  const [dohod_pos, setDohod_pos] = useState("");
  const [pr_rekl_pok, setPr_rekl_pok] = useState("");
  const [interes_pok, setInteres_pok] = useState("");
  const [rekom_vip_pok, setRekom_vip_pok] = useState("");
  const [stavka_mlz_pok, setStavka_mlz_pok] = useState("");
  const [vip_doh_pok_2, setVip_doh_pok_2] = useState("");
  const [stavka_mlz_pok2, setStavka_mlz_pok2] = useState("");
  const [dohod_pok, setDohod_pok] = useState("");
  const [dohod_pos_mzl1, setDohod_pos_mzl] = useState("");
  const [pr_vip_pos1, setPr_vip_pos] = useState("");
  const [dohod_pok_mzl1, setDohod_pok_mzl] = useState("");
  const [pr_vip_pok1, setPr_vip_pok] = useState("");
  const [stop_otgr, setStop_otgr] = useState("");
  const [s_mzl_only_card, setS_mzl_only_card] = useState("");
  const [s_mzl_not_card, setS_mzl_not_card] = useState("");
  const [s_vip_only_card, setS_vip_only_card] = useState("");
  const [s_vip_not_card, setS_vip_not_card] = useState("");

  useEffect(() => {  // хук позволяет добавлять побочные эффекты к заданному функциональному компоненту
    const fetchPrice = async () => {
      if (idstr.length === 0) {
       console.log('Wait')
      }
      else {  // получает данные
        const response = await fetch(`/api/get_str/${idstr}`, {method: "GET"})
        const lol = await response.json();
        setName(lol.data[0]['name'])
        setCommer(lol.data[0]['commer'])
        setPzy(lol.data[0]['pzy'])
        setVid_loma(lol.data[0]['vid_loma'])
        setNamen(lol.data[0]['name'])
        setCommern(lol.data[0]['commer'])
        setPzyn(lol.data[0]['pzy'])
        setVid_loman(lol.data[0]['vid_loma'])
        setPr_rekl_pos(lol.data[0]['pr_rekl_pos'])
        setInteres_pos(lol.data[0]['interes_pos'])
        setNalog_bank_pos(lol.data[0]['nalog_bank_pos'])
        setZasor_pos(lol.data[0]['zasor_pos'])
        setZak_zas_pos(lol.data[0]['zak_zas_pos'])
        setZak_zas(lol.data[0]['zak_zas'])
        setDop_zas_pos(lol.data[0]['dop_zas_pos'])
        setRekom_vip_pos(lol.data[0]['rekom_vip_pos'])
        setStavka_mlz_pos(lol.data[0]['stavka_mlz_pos'])
        setVip_doh_pos_2(lol.data[0]['vip_doh_pos_2'])
        setStavka_mlz_pos2(lol.data[0]['stavka_mlz_pos2'])
        setDohod_pos(lol.data[0]['dohod_pos'])
        setPr_rekl_pok(lol.data[0]['pr_rekl_pok'])
        setInteres_pok(lol.data[0]['interes_pok'])
        setRekom_vip_pok(lol.data[0]['rekom_vip_pok'])
        setStavka_mlz_pok(lol.data[0]['stavka_mlz_pok'])
        setVip_doh_pok_2(lol.data[0]['vip_doh_pok_2'])
        setStavka_mlz_pok2(lol.data[0]['stavka_mlz_pok2'])
        setDohod_pok(lol.data[0]['dohod_pok'])
        setDohod_pos_mzl(lol.data[0]['dohod_pos_mzl'])
        setPr_vip_pos(lol.data[0]['pr_vip_pos'])
        setDohod_pok_mzl(lol.data[0]['dohod_pok_mzl'])
        setPr_vip_pok(lol.data[0]['pr_vip_pok'])
        setStop_otgr(lol.data[0]['stop_otgr'])
        setS_mzl_only_card(lol.data[0]['s_mzl_only_card'])
        setS_mzl_not_card(lol.data[0]['s_mzl_not_card'])
        setS_vip_only_card(lol.data[0]['s_vip_only_card'])
        setS_vip_not_card(lol.data[0]['s_vip_not_card'])
      }
    };
    fetchPrice();
  }, [idstr, name, newone, zak_zas]);

  const {
    register, handleSubmit, reset, watch, resetField, setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {  // устанавливает изначальные данные
      data_all: '',
      time: '',
      name: newone === true ? '' : name,
      commer: newone === true ? '' : commer,
      pzy: newone === true ? '' : pzy,
      vid_loma: newone === true ? '' : vid_loma,
      namen: name,
      pzyn: pzy,
      commern: commer,
      vid_loman: vid_loma,
      pr_rekl_pos: newone === true ? '' : pr_rekl_pos,
      interes_pos: newone === true ? '' : interes_pos,
      nalog_bank_pos: newone === true ? '' : nalog_bank_pos,
      zasor_pos: newone === true ? '' : zasor_pos,
      zak_zas_pos: newone === true ? '' : zak_zas_pos,
      dop_zas_pos: newone === true ? '' : dop_zas_pos,
      rekom_vip_pos: newone === true ? '' : rekom_vip_pos,
      stavka_mlz_pos: newone === true ? '' : stavka_mlz_pos,
      vip_doh_pos_2: newone === true ? '' : vip_doh_pos_2,
      stavka_mlz_pos2: newone === true ? '' : stavka_mlz_pos2,
      dohod_pos: newone === true ? '' : dohod_pos,
      pr_rekl_pok: newone === true ? '' : pr_rekl_pok,
      interes_pok: newone === true ? '' : interes_pok,
      rekom_vip_pok: newone === true ? '' : rekom_vip_pok,
      stavka_mlz_pok: newone === true ? '' : stavka_mlz_pok,
      vip_doh_pok_2: newone === true ? '' : vip_doh_pok_2,
      stavka_mlz_pok2: newone === true ? '' : stavka_mlz_pok2,
      dohod_pok: newone === true ? '' : dohod_pok,
      dohod_pos_mzl1: newone === true ? '' : dohod_pos_mzl1,
      pr_vip_pos1: newone === true ? '' : pr_vip_pos1,
      dohod_pok_mzl1: newone === true ? '' : dohod_pok_mzl1,
      pr_vip_pok1: newone === true ? '' : pr_vip_pok1,
      stop_otgr: stop_otgr ? stop_otgr : newone === true ? 0 : 0,
      s_mzl_only_card: newone === true ? '' : s_mzl_only_card,
      s_mzl_not_card: newone === true ? '' : s_mzl_not_card,
      s_vip_only_card: newone === true ? '' : s_vip_only_card,
      s_vip_not_card: newone === true ? '' : s_vip_not_card,
      zak_zas: zak_zas === 1 ? true : false,
      isPzy: false,
    },
  });

  useEffect(() => {  // сбрасывает изначальные и устанавливает полученные
    if (name) {
      resetField('pr_rekl_pos', { defaultValue: pr_rekl_pos });
      resetField('interes_pos', { defaultValue: interes_pos });
      resetField('nalog_bank_pos', { defaultValue: nalog_bank_pos });
      resetField('zasor_pos', { defaultValue: zasor_pos });
      resetField('zak_zas_pos', { defaultValue: zak_zas_pos });
      resetField('dop_zas_pos', { defaultValue: dop_zas_pos });
      resetField('rekom_vip_pos', { defaultValue: rekom_vip_pos });
      resetField('stavka_mlz_pos', { defaultValue: stavka_mlz_pos });
      resetField('vip_doh_pos_2', { defaultValue: vip_doh_pos_2 });
      resetField('stavka_mlz_pos2', { defaultValue: stavka_mlz_pos2 });
      resetField('dohod_pos', { defaultValue: dohod_pos });
      resetField('pr_rekl_pok', { defaultValue: pr_rekl_pok });
      resetField('interes_pok', { defaultValue: interes_pok });
      resetField('rekom_vip_pok', { defaultValue: rekom_vip_pok });
      resetField('stavka_mlz_pok', { defaultValue: stavka_mlz_pok });
      resetField('vip_doh_pok_2', { defaultValue: vip_doh_pok_2 });
      resetField('stavka_mlz_pok2', { defaultValue: stavka_mlz_pok2 });
      resetField('dohod_pok', { defaultValue: dohod_pok });
      resetField('stop_otgr', { defaultValue: stop_otgr });
      resetField('s_mzl_only_card', { defaultValue: s_mzl_only_card });
      resetField('s_mzl_not_card', { defaultValue: s_mzl_not_card });
      resetField('s_vip_only_card', { defaultValue: s_vip_only_card });
      resetField('s_vip_not_card', { defaultValue: s_vip_not_card });
      resetField('zak_zas', { defaultValue: zak_zas });
      resetField('namen', { defaultValue: name });
      resetField('pzyn', { defaultValue: pzy });
      resetField('commern', { defaultValue: commer });
      resetField('vid_loman', { defaultValue: vid_loma });
    }
  }, [resetField, pr_rekl_pos, interes_pos, nalog_bank_pos, zasor_pos, zak_zas_pos, dop_zas_pos, rekom_vip_pos,
  dohod_pok, dohod_pos, interes_pok, pr_rekl_pok, vip_doh_pok_2, vip_doh_pos_2, rekom_vip_pok, stavka_mlz_pok,
  stavka_mlz_pok2, stavka_mlz_pos, stavka_mlz_pos2, stop_otgr, s_mzl_only_card, s_mzl_not_card, s_vip_only_card,
  s_vip_not_card, zak_zas, name, pzy, commer, vid_loma]);

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const current = new Date();
  const today_date = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' +
                     ('0' + current.getDate()).slice(-2);

  var getDaysArray = function(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

  const pr_contr_pos = Number(watchAllFields.pr_rekl_pos)-Number(watchAllFields.interes_pos)
  const nalog_bank_pos_1 = Math.round(Number(watchAllFields.pr_rekl_pos)/100*Number(watchAllFields.nalog_bank_pos))
  const nalog_bank_pos_2 = Math.round(pr_contr_pos-nalog_bank_pos_1)
  const zasor_pos_1 = Math.round(Number(watchAllFields.pr_rekl_pos)/100*Number(watchAllFields.zasor_pos))
  const zasor_pos_2 = nalog_bank_pos_2-zasor_pos_1
  const dop_zas_pos_1 = Math.round(Number(watchAllFields.pr_rekl_pos)/100*Number(watchAllFields.dop_zas_pos))
  const dop_zas_pos_2 = zasor_pos_2-dop_zas_pos_1
  const zasor_razn = Number(watchAllFields.zak_zas_pos)-Number(watchAllFields.zasor_pos)
  const pr_vip_pos = pr_contr_pos-Number(watchAllFields.stavka_mlz_pos2)-Number(watchAllFields.vip_doh_pos_2)
  const r_vip_doh_pos_0 = Number(watchAllFields.pr_rekl_pos)/100*zasor_razn
  const r_vip_doh_pos_1 = (Number(watchAllFields.zasor_pos) === Number(watchAllFields.zak_zas_pos))
    ? Math.round(nalog_bank_pos_2-Number(watchAllFields.rekom_vip_pos))
    : Math.round(nalog_bank_pos_2-Number(watchAllFields.rekom_vip_pos)+r_vip_doh_pos_0);
  const r_vip_doh_pos_2 = r_vip_doh_pos_1-Number(watchAllFields.stavka_mlz_pos)
  const dohod_pos_mzl = Math.round(pr_contr_pos-Number(watchAllFields.dohod_pos))

  const pr_contr_pok = Number(watchAllFields.pr_rekl_pok)-Number(watchAllFields.interes_pok)
  const nalog_bank_pok = Number(watchAllFields.nalog_bank_pos)
  const nalog_bank_pok_1 = Math.round(Number(watchAllFields.pr_rekl_pok)/100*nalog_bank_pok)
  const nalog_bank_pok_2 = Math.round(pr_contr_pok-nalog_bank_pok_1)
  const zasor_pok = Number(watchAllFields.zasor_pos)
  const zasor_pok_1 = Math.round(Number(watchAllFields.pr_rekl_pok)/100*zasor_pok)
  const zasor_pok_2 = nalog_bank_pok_2-zasor_pok_1
  const dop_zas_pok = Number(watchAllFields.dop_zas_pos)
  const dop_zas_pok_1 = Math.round(Number(watchAllFields.pr_rekl_pok)/100*dop_zas_pok)
  const dop_zas_pok_2 = zasor_pok_2-dop_zas_pok_1
  const pr_vip_pok = pr_contr_pok-Number(watchAllFields.stavka_mlz_pok2)-Number(watchAllFields.vip_doh_pok_2)
  const r_vip_doh_pok_0 = Number(watchAllFields.pr_rekl_pok)/100*zasor_razn
  const r_vip_doh_pok_1 = (Number(watchAllFields.zasor_pos) === Number(watchAllFields.zak_zas_pos))
    ? Math.round(nalog_bank_pok_2-Number(watchAllFields.rekom_vip_pok))
    : Math.round(nalog_bank_pok_2-Number(watchAllFields.rekom_vip_pok)+r_vip_doh_pok_0);
  const r_vip_doh_pok_2 = r_vip_doh_pok_1-Number(watchAllFields.stavka_mlz_pok)
  const dohod_pok_mzl = Math.round(pr_contr_pok-Number(watchAllFields.dohod_pok))

  const pr_mzl_only_card = Number(dohod_pos_mzl)-Number(watchAllFields.s_mzl_only_card)
  const pr_mzl_not_card = Number(dohod_pos_mzl)-Number(watchAllFields.s_mzl_not_card)
  const pr_vip_only_card = Number(pr_vip_pos)-Number(watchAllFields.s_vip_only_card)
  const pr_vip_not_card = Number(pr_vip_pos)-Number(watchAllFields.s_vip_not_card)

  const onSubmit = async (data, e) => {
    const in_fact = (data.zak_zas === true || data.zak_zas === 1) ? 1 : 0
    const zak_zas_pos = (data.zak_zas === true || data.zak_zas === 1) ? 6 : data.zak_zas_pos
    const time = data.time ? data.time : "00:00"
    const data_all = data.data_all +" "+ time

    if (data.isPzy !== true && newone === true) {
      const name_ok = data.namen.trim().replace('/', '-').replace(/\\/,'-');
      const pzy_ok = data.pzyn.trim().replace('/', '-').replace(/\\/,'-');
      const vid_loma_ok = data.vid_loman.trim().replace(/[//\\/]/g,'-');

      const s_mzl_only_card_n = data.s_mzl_only_card ? data.s_mzl_only_card : 0
      const s_mzl_not_card_n = data.s_mzl_not_card ? data.s_mzl_not_card : 0
      const s_vip_only_card_n = data.s_vip_only_card ? data.s_vip_only_card : 0
      const s_vip_not_card_n = data.s_vip_not_card ? data.s_vip_not_card : 0

      await fetch(`/api/add_changes?data_all=${data_all}&name=${name_ok}&commer=${data.commer}` +
                  `&pzy=${pzy_ok}&vid_loma=${vid_loma_ok}&vip_pos_o=${0}&mzl_pos_o=${0}` +
                  `&vip_pok_o=${0}&mzl_pok_o=${0}&vip_pos_n=${pr_vip_pos}` +
                  `&mzl_pos_n=${dohod_pos_mzl}&vip_pok_n=${pr_vip_pok}&mzl_pok_n=${dohod_pok_mzl}`, {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
        }),
      });

      const response = await fetch(`/api/add_new?data_all=${data_all}` +
                  `&name=${name_ok}&commer=${data.commer}&pzy=${pzy_ok}` +
                  `&vid_loma=${vid_loma_ok}&pr_rekl_pos=${data.pr_rekl_pos}` +
                  `&interes_pos=${data.interes_pos}&pr_contr_pos=${pr_contr_pos}` +
                  `&nalog_bank_pos=${data.nalog_bank_pos}&nalog_bank_pos_2=${nalog_bank_pos_2}` +
                  `&zasor_pos=${data.zasor_pos}&zasor_pos_2=${zasor_pos_2}` +
                  `&zak_zas_pos=${zak_zas_pos}&dop_zas_pos=${data.dop_zas_pos}` +
                  `&dop_zas_pos_2=${dop_zas_pos_2}&rekom_vip_pos=${data.rekom_vip_pos}` +
                  `&stavka_mlz_pos=${data.stavka_mlz_pos}&r_vip_doh_pos_2=${r_vip_doh_pos_2}` +
                  `&pr_vip_pos=${pr_vip_pos}&stavka_mlz_pos2=${data.stavka_mlz_pos2}` +
                  `&vip_doh_pos_2=${data.vip_doh_pos_2}&dohod_pos=${data.dohod_pos}` +
                  `&dohod_pos_mzl=${dohod_pos_mzl}&pr_rekl_pok=${data.pr_rekl_pok}` +
                  `&interes_pok=${data.interes_pok}&pr_contr_pok=${pr_contr_pok}` +
                  `&nalog_bank_pok_2=${nalog_bank_pok_2}&zasor_pok_2=${zasor_pok_2}&dop_zas_pok_2=${dop_zas_pok_2}` +
                  `&rekom_vip_pok=${data.rekom_vip_pok}&stavka_mlz_pok=${data.stavka_mlz_pok}` +
                  `&r_vip_doh_pok_2=${r_vip_doh_pok_2}&pr_vip_pok=${pr_vip_pok}` +
                  `&stavka_mlz_pok2=${data.stavka_mlz_pok2}&vip_doh_pok_2=${data.vip_doh_pok_2}` +
                  `&dohod_pok=${data.dohod_pok}&dohod_pok_mzl=${dohod_pok_mzl}&zak_zas=${in_fact}&stop_otgr=${0}` +
                  `&s_mzl_only_card=${s_mzl_only_card_n}&s_mzl_not_card=${s_mzl_not_card_n}` +
                  `&s_vip_only_card=${s_vip_only_card_n}&s_vip_not_card=${s_vip_not_card_n}`, {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
        }),
      });
      if (response.ok) {
        e.target.reset();
        window.location.reload();
      }
    }
    else {
      var daylist = getDaysArray(data.data_all, new Date());
      daylist.map((v)=>v.toISOString().slice(0,10)).join("")

      let pzy_all;
      if (data.isPzy === true && newone !== true) {
        const response = await fetch(`/api/get_pzy/${name}/${commer}/` +
                  `${vid_loma}`, {method: "GET"})
        const data = await response.json()  // извлечь полученные данные в виде JSON,
        pzy_all = data.data
      }
      else {
        pzy_all = [ { pzy: pzy } ]
      }

      for(const pzy of pzy_all){

          await fetch(`/api/add_changes?data_all=${data_all}&name=${name}&commer=${commer}` +
                      `&pzy=${pzy.pzy}&vid_loma=${vid_loma}&vip_pos_o=${pr_vip_pos1}&mzl_pos_o=${dohod_pos_mzl1}` +
                      `&vip_pok_o=${pr_vip_pok1}&mzl_pok_o=${dohod_pok_mzl1}&vip_pos_n=${pr_vip_pos}` +
                      `&mzl_pos_n=${dohod_pos_mzl}&vip_pok_n=${pr_vip_pok}&mzl_pok_n=${dohod_pok_mzl}`, {
            method: "POST",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
            }),
          });

          for(const day of daylist){
            const one_day = day.toISOString().slice(0,10)
            const time = (one_day === data.data_all && data.time ) ? data.time : "00:00"
            const data_al = one_day +" "+ time

            await fetch(`/api/add_new?data_all=${data_al}` +
                  `&name=${name}&commer=${commer}&pzy=${pzy.pzy}` +
                  `&vid_loma=${vid_loma}&pr_rekl_pos=${data.pr_rekl_pos}` +
                  `&interes_pos=${data.interes_pos}&pr_contr_pos=${pr_contr_pos}` +
                  `&nalog_bank_pos=${data.nalog_bank_pos}&nalog_bank_pos_2=${nalog_bank_pos_2}` +
                  `&zasor_pos=${data.zasor_pos}&zasor_pos_2=${zasor_pos_2}` +
                  `&zak_zas_pos=${zak_zas_pos}&dop_zas_pos=${data.dop_zas_pos}` +
                  `&dop_zas_pos_2=${dop_zas_pos_2}&rekom_vip_pos=${data.rekom_vip_pos}` +
                  `&stavka_mlz_pos=${data.stavka_mlz_pos}&r_vip_doh_pos_2=${r_vip_doh_pos_2}` +
                  `&pr_vip_pos=${pr_vip_pos}&stavka_mlz_pos2=${data.stavka_mlz_pos2}` +
                  `&vip_doh_pos_2=${data.vip_doh_pos_2}&dohod_pos=${data.dohod_pos}` +
                  `&dohod_pos_mzl=${dohod_pos_mzl}&pr_rekl_pok=${data.pr_rekl_pok}` +
                  `&interes_pok=${data.interes_pok}&pr_contr_pok=${pr_contr_pok}` +
                  `&nalog_bank_pok_2=${nalog_bank_pok_2}&zasor_pok_2=${zasor_pok_2}&dop_zas_pok_2=${dop_zas_pok_2}` +
                  `&rekom_vip_pok=${data.rekom_vip_pok}&stavka_mlz_pok=${data.stavka_mlz_pok}` +
                  `&r_vip_doh_pok_2=${r_vip_doh_pok_2}&pr_vip_pok=${pr_vip_pok}` +
                  `&stavka_mlz_pok2=${data.stavka_mlz_pok2}&vip_doh_pok_2=${data.vip_doh_pok_2}` +
                  `&dohod_pok=${data.dohod_pok}&dohod_pok_mzl=${dohod_pok_mzl}&zak_zas=${in_fact}&stop_otgr=${0}` +
                  `&s_mzl_only_card=${data.s_mzl_only_card}&s_mzl_not_card=${data.s_mzl_not_card}` +
                  `&s_vip_only_card=${data.s_vip_only_card}&s_vip_not_card=${data.s_vip_not_card}`, {
              method: "POST",
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({
            }),
          });
        }
      }
      window.location.reload();
    }
  };

  async function setStopOtg(){
    const response = await fetch(`/api/stop_otg/${idstr}?stop_otgr=${1}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  async function setReturnOtg(){
    const response = await fetch(`/api/stop_otg/${idstr}?stop_otgr=${0}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  const deleteName = async () => {
    const name_del = `${name} УДАЛЕНО`
    const response = await fetch(`/api/delete_str/${idstr}?name=${name_del}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  async function stavkaAll(){
    const resp = await fetch(`/api/stavka_all?` +
                 `s_mzl_only_card=${watchAllFields.s_mzl_only_card}&s_vip_only_card=${watchAllFields.s_vip_only_card}` +
                 `&s_mzl_not_card=${watchAllFields.s_mzl_not_card}&s_vip_not_card=${watchAllFields.s_vip_not_card}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (resp.ok) {
      window.location.reload();
    }
  };

  async function stavkaOne(){
    const resp = await fetch(`/api/stavka_one/${idstr}?` +
                 `s_mzl_only_card=${watchAllFields.s_mzl_only_card}&s_vip_only_card=${watchAllFields.s_vip_only_card}` +
                 `&s_mzl_not_card=${watchAllFields.s_mzl_not_card}&s_vip_not_card=${watchAllFields.s_vip_not_card}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (resp.ok) {
      window.location.reload();
    }
  };

  const renameAll = async () => {
    const name_ok = watchAllFields.namen.trim().replace('/', '-').replace(/\\/,'-');
    const pzy_ok = watchAllFields.pzyn.trim().replace('/', '-').replace(/\\/,'-');
    const vid_loma_ok = watchAllFields.vid_loman.trim().replace(/[//\\/]/g,'-');

    const response = await fetch(`/api/rename_all/${name}/${commer}/${pzy}/` +
                    `${vid_loma}?namen=${name_ok}&commern=${watchAllFields.commern}` +
                    `&pzyn=${pzy_ok}&vid_loman=${vid_loma_ok}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  const sendPrice = async () => {  // window with alert that's all ok
    const response = await fetch(`/api/change_status`, {method: "GET"})
    const data = await response.json();
    if (data.data.length === 0) {
      return (
        toast({
          title: 'Не было обновлений цен',
          description: "Нет информации для публикации",
          status: 'info',
          duration: 9000,
          isClosable: true,
        })
      )
    }
    else {
      let name_text = "";
      const name_all = data.data
      for(const name of name_all){
        name_text += name.name + " "
      }
      return (
        toast({
          title: 'Готово',
          description: `Инфо об обновлении цен ${name_text} будет опубликовано`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      )
    }
  };

  useEffect(() => {
    if (watchAllFields.pr_rekl_pos === "0") {
       setValue("interes_pos", 0);
       setValue("rekom_vip_pos", 0);
       setValue("stavka_mlz_pos", 0);
       setValue("vip_doh_pos_2", 0);
       setValue("stavka_mlz_pos2", 0);
       setValue("dohod_pos", 0);
    };
    if (watchAllFields.pr_rekl_pok === "0") {
       setValue("interes_pok", 0);
       setValue("rekom_vip_pok", 0);
       setValue("stavka_mlz_pok", 0);
       setValue("vip_doh_pok_2", 0);
       setValue("stavka_mlz_pok2", 0);
       setValue("dohod_pok", 0);
    };
    if (watchAllFields.zak_zas === true) {
       setValue("zak_zas_pos", 6);
    }
  }, [setValue, watchAllFields]);

  const onFormReset = (data, e) => {
    reset();
  };

  return (
    <Container p={5} maxW="140ch">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={5} textAlign='center'>
          {newone !== true ? (
            <Box bg='yellow.400' pb={2}>
              <Heading size='lg' pb={2}>{name} {commer} {pzy} {vid_loma}</Heading>
              <Button onClick={modal3.onOpen} colorScheme='orange' size='xs' variant='outline'>
                Редактировать
              </Button>
              <Modal isOpen={modal3.isOpen} size={'lg'} onClose={modal3.onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Редактировать</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <form>
                      <SimpleGrid columns={2} spacingX='10px'>
                        <FormControl isRequired>
                          <FormLabel textAlign="center">Название</FormLabel>
                          <Input
                            onKeyDown={handleEnter}
                            {...register("namen")}
                            defaultValue={namen}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel textAlign="center">Коммерч.</FormLabel>
                          <Select placeholder='Выберите'
                            {...register("commern")}
                            defaultValue={commern}
                            onKeyDown={handleEnter}>
                            <option value='Ком'>Ком</option>
                            <option value='Неком'>Нком</option>
                          </Select>
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel textAlign="center">ПЗУ</FormLabel>
                          <Input
                            onKeyDown={handleEnter}
                            {...register("pzyn")}
                            defaultValue={pzyn}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel textAlign="center">Категория лома</FormLabel>
                          <Input
                            onKeyDown={handleEnter}
                            {...register("vid_loman")}
                            defaultValue={vid_loman}
                          />
                        </FormControl>
                      </SimpleGrid>
                      <ButtonGroup p='4' spacing='3' position='relative' left= "50%" transform="translate(-50%, 0)">
                        <Button
                          type="reset"
                          onClick={() => onFormReset()}
                        >
                          Отмена
                        </Button>
                        <Button
                          colorScheme='yellow'
                          onClick={() => renameAll()}
                        >
                          Принять
                        </Button>
                      </ButtonGroup>
                    </form>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          ) : (
            <Box bg='yellow.400' pb={5} />
          )}
          <SimpleGrid columns={4} spacingX='10px'>
            <Popover>
              <PopoverTrigger>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Дата</FormLabel>
                  <Input
                    type='date'
                    defaultValue=''
                    onKeyDown={handleEnter}
                    {...register("data_all")}
                  />
                </FormControl>
              </PopoverTrigger>
              { (watchAllFields.data_all > today_date) ? (
                <PopoverContent bg='red.200' width='100%'>
                  <PopoverCloseButton />
                  <PopoverHeader>
                    Не может быть<br />больше сегодняшней
                  </PopoverHeader>
                </PopoverContent>
              ):(
                null
              )}
            </Popover>

            <FormControl>
              <FormLabel textAlign="center">Время</FormLabel>
              <Input
                type='time'
                defaultValue='00:00'
                onKeyDown={handleEnter}
                {...register("time")}
              />
            </FormControl>
            <FormControl />
            {newone !== true ? (
              <SimpleGrid columns={2} spacingX='10px'>
                <FormControl />
                <Menu>
                  <MenuButton as={Button}>
                    ...
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={modal4.onOpen}>Изменить ставку</MenuItem>
                    {stop_otgr !== 1 ? (
                      <MenuItem onClick={modal1.onOpen}>Стоп-отгрузка</MenuItem>
                      ):(
                      <MenuItem onClick={modal1.onOpen}>Отмена Стоп-отгр</MenuItem>
                      )}
                    <MenuItem onClick={modal2.onOpen}>Удалить</MenuItem>
                  </MenuList>
                </Menu>

                <Modal isOpen={modal1.isOpen} onClose={modal1.onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    {stop_otgr !== 1 ? (
                      <ModalHeader>Стоп-отгрузка</ModalHeader>
                      ):(
                      <ModalHeader>Отменить Стоп-отгрузку</ModalHeader>
                      )}
                    <ModalCloseButton />
                    {stop_otgr !== 1 ? (
                      <ModalBody>
                        Установить для {name} {commer} {pzy} {vid_loma} статус Стоп-отгрузка?
                      </ModalBody>
                       ):(
                      <ModalBody>
                        Отменить для {name} {commer} {pzy} {vid_loma} статус Стоп-отгрузка?
                      </ModalBody>
                    )}
                    <ModalFooter>
                      <Button mr={3} onClick={modal1.onClose}>
                        Отмена
                      </Button>
                      {stop_otgr !== 1 ? (
                        <Button colorScheme='yellow' onClick={setStopOtg}>
                          Да, установить
                        </Button>
                      ):(
                        <Button colorScheme='yellow' onClick={setReturnOtg}>
                          Да, отменить
                        </Button>
                      )}
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Modal isOpen={modal2.isOpen} onClose={modal2.onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Удалить</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      Удалить {name} {commer} {pzy} {vid_loma}?
                    </ModalBody>

                    <ModalFooter>
                      <Button mr={3} onClick={modal2.onClose}>
                        Отмена
                      </Button>
                      <Button colorScheme='yellow' onClick={deleteName}>
                        Да, удалить
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Modal isOpen={modal4.isOpen} size={'xl'} onClose={modal4.onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Изменить ставки для {name} {pzy} {vid_loma}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <form>
                        <SimpleGrid columns={2} spacingX='10px' spacingY='20px' textAlign='center'>
                          <SimpleGrid columns={1} spacingX='5px' borderRight='0.5px solid grey' pb='15px'>
                            <Heading size='md' as='u' color='#f58d3a' pb='15px'>МЗЛ</Heading>
                            <FormControl>
                              <Text fontSize='lg'>К/П</Text>
                              <Text fontSize='2xl' as='b'>{dohod_pos_mzl.toLocaleString('de-CH')}</Text>
                            </FormControl>
                          </SimpleGrid>
                          <SimpleGrid columns={1} spacingX='5px' pb='15px'>
                            <Heading size='md' as='u' color='#f58d3a' pb='15px'>VIP</Heading>
                            <FormControl>
                              <Text fontSize='lg'>К/П</Text>
                              <Text fontSize='2xl' as='b'>{pr_vip_pos.toLocaleString('de-CH')}</Text>
                            </FormControl>
                          </SimpleGrid>
                        </SimpleGrid>
                        <SimpleGrid columns={2} spacingX='10px' textAlign='center'>
                          <SimpleGrid columns={1} spacingX='5px' borderRight='0.5px solid grey' pb='5px'>
                            <Text fontSize='lg'>К/0</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={1} spacingX='5px' pb='5px'>
                            <Text fontSize='lg'>К/0</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={2} borderRight='0.5px solid grey' pb='15px'>
                            <Input
                              type='number'
                              onWheel={(event) => event.target.blur()}
                              onKeyDown={handleEnter}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              defaultValue={s_mzl_only_card}
                              {...register("s_mzl_only_card")}
                            />
                            <Text fontSize='2xl' as='b'>{pr_mzl_only_card.toLocaleString('de-CH')}</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={2} pb='15px'>
                            <Input
                              type='number'
                              onWheel={(event) => event.target.blur()}
                              onKeyDown={handleEnter}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              defaultValue={s_vip_only_card}
                              {...register("s_vip_only_card")}
                            />
                            <Text fontSize='2xl' as='b'>{pr_vip_only_card.toLocaleString('de-CH')}</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={1} spacingX='5px' borderRight='0.5px solid grey' pb='5px'>
                            <Text fontSize='lg'>0/0</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={1} spacingX='5px' pb='5px'>
                            <Text fontSize='lg'>0/0</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={2} borderRight='0.5px solid grey'>
                            <Input
                              type='number'
                              onWheel={(event) => event.target.blur()}
                              onKeyDown={handleEnter}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              defaultValue={s_mzl_not_card}
                              {...register("s_mzl_not_card")}
                            />
                            <Text fontSize='2xl' as='b'>{pr_mzl_not_card.toLocaleString('de-CH')}</Text>
                          </SimpleGrid>
                          <SimpleGrid columns={2}>
                            <Input
                              type='number'
                              onWheel={(event) => event.target.blur()}
                              onKeyDown={handleEnter}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              defaultValue={s_vip_not_card}
                              {...register("s_vip_not_card")}
                            />
                            <Text fontSize='2xl' as='b'>{pr_vip_not_card.toLocaleString('de-CH')}</Text>
                          </SimpleGrid>
                        </SimpleGrid>
                        <ButtonGroup p='2' spacing='3' position='relative' left= "50%" transform="translate(-50%, 0)">
                          <Button
                            type="reset"
                            onClick={() => onFormReset()}
                            mt={3}
                          >
                            Сбросить
                          </Button>
                          <Button
                            colorScheme='yellow'
                            onClick={stavkaOne}
                            mt={3}
                          >
                            Для одного
                          </Button>
                          <Button
                            colorScheme='orange'
                            onClick={stavkaAll}
                            mt={3}
                          >
                            Для всех
                          </Button>
                        </ButtonGroup>
                      </form>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </SimpleGrid>
            ) : (
              null
            )}
          </SimpleGrid>
          {newone === true ? (
            <SimpleGrid columns={1} spacingX='10px'>
              <VStack>
                <Heading size='md' as='u'>ИНФО</Heading>
                <SimpleGrid columns={4} spacingX='10px'>
                  <FormControl isRequired>
                    <FormLabel textAlign="center">Название</FormLabel>
                    <Input
                      onKeyDown={handleEnter}
                      {...register("namen")}
                      defaultValue={namen}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel textAlign="center">Коммерч.</FormLabel>
                    <Select placeholder='Выберите'
                      {...register("commer")}
                      onKeyDown={handleEnter}>
                      <option value='Ком'>Ком</option>
                      <option value='Неком'>Нком</option>
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel textAlign="center">ПЗУ</FormLabel>
                    <Input
                      onKeyDown={handleEnter}
                      {...register("pzyn")}
                      defaultValue={pzyn}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel textAlign="center">Категория лома</FormLabel>
                    <Input
                      onKeyDown={handleEnter}
                      {...register("vid_loman")}
                      defaultValue={vid_loman}
                    />
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </SimpleGrid>
          ) : (
            null
          )}
          <SimpleGrid columns={2} spacingX='10px'>
            <VStack pr={1} borderRight='0.5px solid lightgrey'>
              <Heading size='md' as='u'>ТРАНСПОРТ ПОСТАВЩИКА</Heading>
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Цена рекламная</FormLabel>
                  <Input
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={pr_rekl_pos}
                    {...register("pr_rekl_pos")}
                  />

                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Интерес</FormLabel>
                  <Input
                    {...register("interes_pos")}
                    defaultValue={interes_pos}
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Цена контракта</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_contr_pos.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <>
                <Button onClick={onToggle} colorScheme='yellow'>Налоги банк и засор</Button>
                <Collapse in={ newone === true ? onOpen : isOpen } animateOpacity>
                  <Box
                    p='10px'
                    color='black'
                    mt='1'
                    bg='white'
                    rounded='md'
                  >
                    <SimpleGrid columns={3} spacingX='10px'>
                      <FormControl isRequired>
                        <FormLabel textAlign="center">Налоги банки %</FormLabel>
                        <Input
                          type='number'
                          step='0.1'
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={handleEnter}
                          defaultValue={nalog_bank_pos}
                          {...register("nalog_bank_pos")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Налоги банки</FormLabel>
                        <Text fontSize='2xl' as='b'>{nalog_bank_pos_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Налоги банки</FormLabel>
                        <Text fontSize='2xl' as='b'>{nalog_bank_pos_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={3} spacingX='10px'>
                      <FormControl />
                      <FormControl isRequired>
                        <FormLabel textAlign="center">Закуп Засор %</FormLabel>
                        <Input
                          type='number'
                          step='0.1'
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={handleEnter}
                          defaultValue={zak_zas_pos}
                          {...register("zak_zas_pos")}
                        />
                      </FormControl>
                      <FormControl alignItems='center'>
                        <FormLabel textAlign="center">_</FormLabel>
                        <Checkbox
                          size='md'
                          colorScheme='yellow'
                          isChecked={watchAllFields.zak_zas}
                          defaultValue={zak_zas}
                          {...register("zak_zas")}
                        >
                          "По факту"
                        </Checkbox>
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={3} spacingX='10px'>
                      <FormControl isRequired>
                        <FormLabel textAlign="center">Реал. Засор %</FormLabel>
                        <Input
                          type='number'
                          step='0.1'
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={handleEnter}
                          defaultValue={zasor_pos}
                          {...register("zasor_pos")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{zasor_pos_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{zasor_pos_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={3} spacingX='10px'>
                      <FormControl isRequired>
                        <FormLabel textAlign="center">Доп. засор %</FormLabel>
                        <Input
                          type='number'
                          step='0.1'
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={handleEnter}
                          defaultValue={dop_zas_pos}
                          {...register("dop_zas_pos")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Доп. засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{dop_zas_pos_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Доп. засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{dop_zas_pos_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                  <Box bg='yellow.400' w='100%' p={0.5} />
                </Collapse>
              </>
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Рекоменд. ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={rekom_vip_pos}
                    {...register("rekom_vip_pos")}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={stavka_mlz_pos}
                    {...register("stavka_mlz_pos")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Доход ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{r_vip_doh_pos_2.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_vip_pos.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={stavka_mlz_pos2}
                    {...register("stavka_mlz_pos2")}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Доход ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={vip_doh_pos_2}
                    {...register("vip_doh_pos_2")}
                  />
                </FormControl>
              </SimpleGrid>
              <Box bg='yellow.400' w='100%' p={0.5} />
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>{dohod_pos_mzl.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Ставка МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>1/3</Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Доход</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={dohod_pos}
                    {...register("dohod_pos")}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>
            <VStack>
              <Heading size='md' as='u'>ТРАНСПОРТ ПОКУПАТЕЛЯ</Heading>
              <SimpleGrid columns={3} spacingX='10px' spacingY='11px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Цена рекламная</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={pr_rekl_pok}
                    {...register("pr_rekl_pok")}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Интерес</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={interes_pok}
                    {...register("interes_pok")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Цена контракта</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_contr_pok.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <>
                <Button onClick={onToggle} colorScheme='yellow'>Налоги банк и засор</Button>
                <Collapse in={ newone === true ? onOpen : isOpen } animateOpacity>
                  <Box
                    p='10px'
                    color='black'
                    mt='1'
                    bg='white'
                    rounded='md'
                  >
                    <SimpleGrid columns={3} spacingX='14px'>
                      <FormControl height='72px'>
                        <FormLabel textAlign="center">Налоги банки %</FormLabel>
                        <Text fontSize='2xl' as='b'>{nalog_bank_pok}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Налоги банки</FormLabel>
                        <Text fontSize='2xl' as='b'>{nalog_bank_pok_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Налоги банки</FormLabel>
                        <Text fontSize='2xl' as='b'>{nalog_bank_pok_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl />
                      <FormControl height='72px'>
                        <FormLabel textAlign="center">Закуп Засор %</FormLabel>
                        <Text fontSize='2xl' as='b'>{watchAllFields.zak_zas_pos}</Text>
                      </FormControl>
                      <FormControl />
                      <FormControl  height='72px'>
                        <FormLabel textAlign="center">Реал. Засор %</FormLabel>
                        <Text fontSize='2xl' as='b'>{watchAllFields.zasor_pos}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{zasor_pok_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{zasor_pok_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl height='72px'>
                        <FormLabel textAlign="center">Доп. засор %</FormLabel>
                        <Text fontSize='2xl' as='b'>{watchAllFields.dop_zas_pos}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Доп. засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{dop_zas_pok_1.toLocaleString('de-CH')}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel textAlign="center">Доп. засор</FormLabel>
                        <Text fontSize='2xl' as='b'>{dop_zas_pok_2.toLocaleString('de-CH')}</Text>
                      </FormControl>
                    </SimpleGrid>
                  </Box>
                  <Box bg='yellow.400' w='100%' p={0.5} />
                </Collapse>
              </>
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Рекоменд. ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={rekom_vip_pok}
                    {...register("rekom_vip_pok")}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={stavka_mlz_pok}
                    {...register("stavka_mlz_pok")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Доход ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{r_vip_doh_pok_2.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_vip_pok.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={stavka_mlz_pok2}
                    {...register("stavka_mlz_pok2")}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Доход ВИП</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={vip_doh_pok_2}
                    {...register("vip_doh_pok_2")}
                  />
                </FormControl>
              </SimpleGrid>
              <Box bg='yellow.400' w='100%' p={0.5} />
              <SimpleGrid columns={3} spacingX='10px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>{dohod_pok_mzl.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Ставка МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>1/3</Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Доход</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={dohod_pok}
                    {...register("dohod_pok")}
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>
          </SimpleGrid>
          <HStack>
            <Button
              type="reset"
              bg="tomato"
              py={5}
              px={30}
              color="white"
              fontWeight="bold"
              onClick={() => onFormReset()}
            >
              Сбросить
            </Button>
            <Button
              type="submit"
              bg="teal"
              py={5}
              px={30}
              color="white"
              fontWeight="bold"
              onClick={sendPrice}
            >
              Принять
            </Button>
            {newone !== true ? (
              <Checkbox
                size='md'
                colorScheme='yellow'
                {...register("isPzy")}
              >
                Для всех ПЗУ {name} {vid_loma} ({commer})
              </Checkbox>
            ) : (
              null
            )}
            <Spacer />
            <Link href="#start">
              <Button onClick={forma.onClose} colorScheme='red' variant='outline'>
                Свернуть
              </Button>
            </Link>
          </HStack>
        </Stack>
      </form>
    </Container>
  );
};

export default SignupForm;