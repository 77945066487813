import React, { useState } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Heading,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  useDisclosure, Container, IconButton,
} from "@chakra-ui/react";
import { TimeIcon } from '@chakra-ui/icons'


function CustomTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        ...columns,
      ]);
    }
  );

  return (
    <>
      <Table {...getTableProps()} width='80%' variant='striped' fontSize="small">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th key={column.id}
                  {...column.getHeaderProps([
                    {
                      style: column.style,
                    }
                  ])}
                  isNumeric={column.isNumeric} sx={{bg: '#e8e2db', textAlign: 'center', border:'0.5px solid black',
                    p:'4px', fontSize:"x-small"}}
                >
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}
                  sx={{padding: '4px', textAlign: 'center'}}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
        </Tbody>
      </Table>
    </>
  );
}

const TableHistMzl = ({idstr}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Дата изм.',
        accessor: 'data',
        disableFilters: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'VIP пост.',
        accessor: 'pr_vip_pos',
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.vip_pos_dif < 0) {
            return <div style={{ color: "red" }}>
              {row.original.pr_vip_pos ? row.original.pr_vip_pos.toLocaleString('de-CH') : 0}</div>;
          } else if (row.original.vip_pos_dif > 0) {
            return <div style={{ color: "#32CD32" }}>
              {row.original.pr_vip_pos ? row.original.pr_vip_pos.toLocaleString('de-CH') : 0}</div>;
          } else {
            return (
              <div>{row.original.pr_vip_pos ? row.original.pr_vip_pos.toLocaleString('de-CH') : 0}</div>
            )
          }
        },
      },
      {
        Header: 'МЗЛ пост.',
        accessor: 'dohod_pos_mzl',
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.pos_mzl_dif < 0) {
            return <div style={{ color: "red" }}>
              {row.original.dohod_pos_mzl ? row.original.dohod_pos_mzl.toLocaleString('de-CH') : 0}</div>;
          } else if (row.original.pos_mzl_dif > 0) {
            return <div style={{ color: "#32CD32" }}>
              {row.original.dohod_pos_mzl ? row.original.dohod_pos_mzl.toLocaleString('de-CH') : 0}</div>;
          } else {
            return (
              <div>{row.original.dohod_pos_mzl ? row.original.dohod_pos_mzl.toLocaleString('de-CH') : 0}</div>
            )
          }
        },
      },
      {
        Header: 'VIP покуп.',
        accessor: 'pr_vip_pok',
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.vip_pok_dif < 0) {
            return <div style={{ color: "red" }}>
              {row.original.pr_vip_pok ? row.original.pr_vip_pok.toLocaleString('de-CH') : 0}</div>;
          } else if (row.original.vip_pok_dif > 0) {
            return <div style={{ color: "#32CD32" }}>
              {row.original.pr_vip_pok ? row.original.pr_vip_pok.toLocaleString('de-CH') : 0}</div>;
          } else {
            return (
              <div>{row.original.pr_vip_pok ? row.original.pr_vip_pok.toLocaleString('de-CH') : 0}</div>
            )
          }
        },
      },
      {
        Header: 'МЗЛ покуп.',
        accessor: 'dohod_pok_mzl',
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.pok_mzl_dif < 0) {
            return <div style={{ color: "red" }}>
              {row.original.dohod_pok_mzl ? row.original.dohod_pok_mzl.toLocaleString('de-CH') : 0}</div>;
          } else if (row.original.pok_mzl_dif > 0) {
            return <div style={{ color: "#32CD32" }}>
              {row.original.dohod_pok_mzl ? row.original.dohod_pok_mzl.toLocaleString('de-CH') : 0}</div>;
          } else {
            return (
              <div>{row.original.dohod_pok_mzl ? row.original.dohod_pok_mzl.toLocaleString('de-CH') : 0}</div>
            )
          }
        },
      },
    ],
    [],
  )

  const modal1 = useDisclosure();
  const [name, setName] = useState("");
  const [commer, setCommer] = useState("");
  const [pzy, setPzy] = useState("");
  const [vid_loma, setVid_loma] = useState("");

  const [data_m, setData_m] = useState([]); // use an empty array as initial value

  const fetchStr = async () => {
    if (typeof idstr === "undefined") {
       console.log('Wait')
      }
    else {
      const response = await fetch(`/api/get_str/${idstr}`, {method: "GET"})
      const data = await response.json()
      setName(data.data[0]['name'])
      setCommer(data.data[0]['commer'])
      setPzy(data.data[0]['pzy'])
      setVid_loma(data.data[0]['vid_loma'])

      const response2 = await fetch(`/api/history/${data.data[0]['name']}/` +
                                    `${data.data[0]['pzy']}/${data.data[0]['commer']}/` +
                                    `${data.data[0]['vid_loma']}`, {method: "GET"})
      const data_m = await response2.json()
      setData_m(data_m.data)
    }
  };

  return (
    <Container p={0}>
      <IconButton size='xs' colorScheme='orange' variant='outline' icon={<TimeIcon />}
        onClick={() => {
          fetchStr();
          modal1.onOpen();
        }}
      />
      <Modal isOpen={modal1.isOpen} size={'xl'} onClose={modal1.onClose}>
        <ModalOverlay />
        <ModalContent textAlign='center'>
          <ModalHeader>История изменений цен</ModalHeader>
          <ModalCloseButton />
          <ModalBody align='center' pb='20px'>
            <Heading size='sm' pb='15px'>{name} {commer} {pzy} {vid_loma}</Heading>
            <ChakraProvider>
              <CustomTable columns={columns} data={data_m} />
            </ChakraProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default TableHistMzl;