import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter, useExpanded } from "react-table";
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, chakra, Link, Collapse,
  Flex, Spacer, Heading, Switch, Text, Box, IconButton, Fade, useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, TriangleDownIcon, TriangleUpIcon, ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons'
import SignupForm from './CommonFormUch'
import SearchPrice from './SearchPrice'


// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id}
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      /*if (id === 'pzy') {
        row.subRows.map((value, index) => {
          return options.add(value.values.pzy)
        })
      }*/
      if (id === 'name' && row.values['name'].includes(' ')) {
        options.add(row.values[id].substring(0, row.values[id].indexOf(' ')));
      } else {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e, row) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Все</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function CustomTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      getSubRows: (row) => row.subRows,
      initialState: {
        sortBy: [
          {
            id: 'pr_rekl_pos',
            desc: true
          }
        ],

        hiddenColumns: ["id", "zak_zas_pos", "zasor_pos", "stavka_mlz_pos", "stavka_mlz_pos2",
            "stavka_mlz_pok", "stavka_mlz_pok2"]
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        ...columns,
      ]);
    }
  );

  function showRekVip() {
    allColumns.find((item) => item.id === "zak_zas_pos").toggleHidden();
    allColumns.find((item) => item.id === "zasor_pos").toggleHidden();
    allColumns.find((item) => item.id === "stavka_mlz_pos").toggleHidden();
    allColumns.find((item) => item.id === "stavka_mlz_pos2").toggleHidden();
    allColumns.find((item) => item.id === "stavka_mlz_pok").toggleHidden();
    allColumns.find((item) => item.id === "stavka_mlz_pok2").toggleHidden();
  };

  return (
    <>
      <Flex position='relative'>
        <Spacer />
        <Spacer />
        <Box p={2}>
          <Text>
            Все Столбцы
          </Text>
          <Switch colorScheme='orange' onChange={showRekVip} />
        </Box>
        <Spacer />
      </Flex>
      <Table {...getTableProps()} width='50%'>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th key={column.id}
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      style: column.style,
                    }
                  ])}
                  isNumeric={column.isNumeric}
                  sx={{bg: '#e8e2db', textAlign: 'center', border:'0.5px solid black', p:'4.5px'}}
                >
                  {column.render('Header')}
                  <chakra.span pl='1'>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label='sorted descending' />
                      ) : (
                        <TriangleUpIcon aria-label='sorted ascending' />
                      )
                    ) : column.canSort ? (
                        <Text>⬍</Text>
                    ) : null}
                  </chakra.span>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}
                  sx={{padding: '4px', textAlign: 'center', border:'0.5px solid black', whiteSpace: 'nowrap'}}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>

          )
        })}
        </Tbody>
      </Table>
    </>
  );
}

export default function App() {
  const [idrow, setRow] = useState([]);
  const [newone, setNewone] = useState([]);
  const forma = useDisclosure();

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        Header: () => null, // No header, please
        id: "expander", // Make sure it has an ID
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getExpandedToggleProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}
            >
              {row.isExpanded ? <ChevronDownIcon/> : <ChevronRightIcon/>}
            </span>
          ) : null
      },
      {
        Header: '',
        accessor: "buttons",
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => (
          <Link href="#formaU">
            <IconButton size='sm' colorScheme='red' icon={<EditIcon />}
              onClick={() => {
                forma.onOpen();
                setRow(row.row.original.id);
                setNewone(false);
              }}
            />
          </Link>
        )
      },
      {
        Header: 'ИНФО',
        columns: [
          {
            Header: 'Данные',
            columns: [
              {
                Header: 'ID',
                accessor: 'id',
                disableFilters: true,
              },
              {
                Header: 'Ко',
                accessor: 'name',
                disableSortBy: true,
                Filter: SelectColumnFilter,
                Cell: ({ row }) => {
                  if (row.original.stop_otgr === 1) {
                    return <div style={{ backgroundColor: "red" }}>
                      СТОП {row.original.name}
                    </div>;
                  } else {
                    return (
                      <div>{row.isExpanded || row.depth === 1 ? <div style={{ backgroundColor: "orange" }}>
                        {row.original.name} </div> : row.original.name}</div>
                    )
                  }
                },
              },
              {
                Header: 'Дата изм.',
                accessor: 'data',
                sortInverted: true,
                disableFilters: true,
              },
              {
                Header: 'ПЗУ',
                accessor: 'pzy',
                disableSortBy: true,
                Filter: SelectColumnFilter,
              },
              {
                Header: 'Вид лома',
                accessor: 'vid_loma',
                disableSortBy: true,
                Filter: SelectColumnFilter,
              },
            ],
            style: {backgroundColor: '#f58d3a', color: 'white'},
          },
        ],
        style: {backgroundColor: '#f58d3a', color: 'black'},
      },
      {
        Header: 'Транспорт поставщика',
        columns: [
          {
            Header: 'Основ.',
            columns: [
              {
                Header: 'Цена ПСА',
                accessor: 'pr_rekl_pos',
                disableFilters: true,
                filter: 'between',
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
              {
                Header: '% Засор Закуп',
                accessor: 'zak_zas_pos',
                disableFilters: true,
              },
              {
                Header: '% Засор Реал.',
                accessor: 'zasor_pos',
                disableFilters: true,
              },
            ],
            style: {backgroundColor: '#fab95b', color: 'white'},
          },
          {
            Header: 'МЗЛ',
            columns: [
              {
                Header: 'Цена',
                accessor: 'dohod_pos_mzl',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#fab95b', color: 'white'},
          },
          {
            Header: 'VIP Рек.',
            columns: [
              {
                Header: 'Цена',
                accessor: 'rekom_vip_pos',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
              {
                Header: 'Ставка',
                accessor: 'stavka_mlz_pos',
                disableSortBy: true,
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#fab95b', color: 'white'},
          },
          {
            Header: 'VIP Устан.',
            columns: [
              {
                Header: 'Цена',
                accessor: 'pr_vip_pos',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
              {
                Header: 'Ставка',
                accessor: 'stavka_mlz_pos2',
                disableSortBy: true,
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#fab95b', color: 'white'},
          },
        ],
        style: {backgroundColor: '#fab95b', color: 'black'},
      },
      {
        Header: 'Транспорт покупателя',
        columns: [
          {
            Header: 'Основ.',
            columns: [
              {
                Header: 'Цена ПСА',
                accessor: 'pr_rekl_pok',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#f06119', color: 'white'},
          },
          {
            Header: 'МЗЛ',
            columns: [
              {
                Header: 'Цена',
                sortInverted: true,
                accessor: 'dohod_pok_mzl',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#f06119', color: 'white'},
          },
          {
            Header: 'VIP Рек.',
            columns: [
              {
                Header: 'Цена',
                accessor: 'rekom_vip_pok',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
              {
                Header: 'Ставка',
                accessor: 'stavka_mlz_pok',
                disableSortBy: true,
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#f06119', color: 'white'},
          },
          {
            Header: 'VIP Устан.',
            columns: [
              {
                Header: 'Цена',
                accessor: 'pr_vip_pok',
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
              {
                Header: 'Ставка',
                accessor: 'stavka_mlz_pok2',
                disableSortBy: true,
                disableFilters: true,
                Cell: row => <div>{row.value ? row.value.toLocaleString('de-CH') : 0}</div>
              },
            ],
            style: {backgroundColor: '#f06119', color: 'white'},
          },
        ],
        style: {backgroundColor: '#f06119', color: 'black'},
      },
    ],
    [forma],
  )

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  const [data, setData] = useState([]); // use an empty array as initial value
  const [oldInfo] = useState([]);
  const handleChange = values => {
     const new_val = values.reduce(function (acc, obj) {
      if (obj.commer === "Ком") {
        obj.name = obj.name + " К"
      }
      const x = acc.findIndex((entity) => entity.name===obj.name &&
        entity.commer===obj.commer && entity.vid_loma===obj.vid_loma &&
        entity.zasor_pos===obj.zasor_pos && entity.zak_zas_pos===obj.zak_zas_pos && entity.stop_otgr===obj.stop_otgr &&
        entity.pr_vip_pos===obj.pr_vip_pos && entity.dohod_pos_mzl===obj.dohod_pos_mzl &&
        entity.pr_vip_pok===obj.pr_vip_pok && entity.dohod_pok_mzl===obj.dohod_pok_mzl &&
        entity.zak_zas===obj.zak_zas);
      if (x !== -1) {
        acc[x].subRows.push(obj)
      } else {
        const xx={'id':obj.id, 'name':obj.name, 'commer':obj.commer, 'data':obj.data,
          'pzy':obj.pzy, 'vid_loma':obj.vid_loma, 'zasor_pos':obj.zasor_pos,
          'zak_zas_pos':obj.zak_zas_pos, 'stop_otgr':obj.stop_otgr, 'pr_vip_pos':obj.pr_vip_pos,
          'dohod_pos_mzl':obj.dohod_pos_mzl, 'pr_vip_pok':obj.pr_vip_pok, 'dohod_pok_mzl':obj.dohod_pok_mzl,
          'zak_zas':obj.zak_zas, 'pr_rekl_pos':obj.pr_rekl_pos, 'rekom_vip_pos':obj.rekom_vip_pos,
          'stavka_mlz_pos':obj.stavka_mlz_pos, 'stavka_mlz_pos2':obj.stavka_mlz_pos2, 'pr_rekl_pok':obj.pr_rekl_pok,
          'rekom_vip_pok':obj.rekom_vip_pok, 'stavka_mlz_pok':obj.stavka_mlz_pok,
          'stavka_mlz_pok2':obj.stavka_mlz_pok2, subRows:[]}
        acc.push(xx)
      }
      return acc;
    }, [])
     setData(new_val);
  }

  const older = useDisclosure();

  const fetchPrice = async () => {
    const response = await fetch("/api/boss")  // fetch - выполнить запрос
    const price = await response.json()  // извлечь полученные данные в виде JSON,
    const result = price.data.reduce(function (acc, obj) {
      if (obj.commer === "Ком") {
        obj.name = obj.name + " К"
      }
      const x = acc.findIndex((entity) => entity.name===obj.name &&
        entity.commer===obj.commer && entity.vid_loma===obj.vid_loma &&
        entity.zasor_pos===obj.zasor_pos && entity.zak_zas_pos===obj.zak_zas_pos && entity.stop_otgr===obj.stop_otgr &&
        entity.pr_vip_pos===obj.pr_vip_pos && entity.dohod_pos_mzl===obj.dohod_pos_mzl &&
        entity.pr_vip_pok===obj.pr_vip_pok && entity.dohod_pok_mzl===obj.dohod_pok_mzl &&
        entity.zak_zas===obj.zak_zas);
      if (x !== -1) {
        acc[x].subRows.push(obj)
      } else {
        const xx={'id':obj.id, 'name':obj.name, 'commer':obj.commer, 'data':obj.data,
          'pzy':obj.pzy, 'vid_loma':obj.vid_loma, 'zasor_pos':obj.zasor_pos,
          'zak_zas_pos':obj.zak_zas_pos, 'stop_otgr':obj.stop_otgr, 'pr_vip_pos':obj.pr_vip_pos,
          'dohod_pos_mzl':obj.dohod_pos_mzl, 'pr_vip_pok':obj.pr_vip_pok, 'dohod_pok_mzl':obj.dohod_pok_mzl,
          'zak_zas':obj.zak_zas, 'pr_rekl_pos':obj.pr_rekl_pos, 'rekom_vip_pos':obj.rekom_vip_pos,
          'stavka_mlz_pos':obj.stavka_mlz_pos, 'stavka_mlz_pos2':obj.stavka_mlz_pos2, 'pr_rekl_pok':obj.pr_rekl_pok,
          'rekom_vip_pok':obj.rekom_vip_pok, 'stavka_mlz_pok':obj.stavka_mlz_pok,
          'stavka_mlz_pok2':obj.stavka_mlz_pok2, subRows:[]}
        acc.push(xx)
      }
      return acc;
    }, [])
    setData(result)
  }
  useEffect(() => {
    fetchPrice()
  }, [])

  return (
    <ChakraProvider>
      <Flex>
        <Spacer />
        <Heading p='2' as='h4' size='md' id="startU">
          Дата: {date}
        </Heading>
        <Spacer />
        <SearchPrice handleChange={handleChange} user={'admin'} />
      </Flex>
      <Collapse in={older.isOpen} animateOpacity sx={{overflow: 'scroll'}}>
        <CustomTable
          columns={columns}
          data={oldInfo}
        />
      </Collapse>
      <CustomTable columns={columns} data={data} />
      <Fade id="form" in={forma.isOpen}>
        <Box
          p='10px'
          color='black'
          mt='1'
          bg='white'
          rounded='md'
          id="formaU"
          width='1100px'
        >
          <SignupForm
            idstr={idrow}
            newone={newone}
            forma={forma}
          />
        </Box>
      </Fade>
    </ChakraProvider>
  );
}