import {
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { RepeatIcon, Search2Icon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form';
import React, { useState } from "react";


const SearchPrice = ({handleChange, handleChange1, handleChange2, user}) => {
  const { register, handleSubmit, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      data_all: '',
    },
  });
  const onSubmit = async (data, e) => {
    const data_all = data.data_all +" 00:00"

    let response;
    let response1;
    let response2;
    if (user === 'admin') {
      response = await fetch(`/api/boss_old/${data_all}`, {method: "GET"})
      const old = await response.json()  // извлечь полученные данные в виде JSON,
      handleChange(old.data)
    } else {
      response = await fetch(`/api/mzl_old/${data_all}`, {method: "GET"})
      response1 = await fetch(`/api/mzl_old_only_card/${data_all}`, {method: "GET"})
      response2 = await fetch(`/api/mzl_old_not_card/${data_all}`, {method: "GET"})
      const old = await response.json()  // извлечь полученные данные в виде JSON,
      const old1 = await response1.json()  // извлечь полученные данные в виде JSON,
      const old2 = await response2.json()  // извлечь полученные данные в виде JSON,
      handleChange(old.data)
      handleChange1(old1.data)
      handleChange2(old2.data)
    }

    e.target.reset(); // reset after form submit
  };

  const modal2 = useDisclosure();
  const modal3 = useDisclosure();
  const [erkz_price, setErkz_price] = useState("");

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const onFormReset = (data, e) => {
    reset();
  };

  const getErkz = async () => {  // window with alert that's all ok
    const response = await fetch(`/api/erkz_price`, {method: "GET"})
    const data = await response.json();
    setErkz_price(data.data[0]['price'])
  };

  const sendErkz = async () => {
    const person = localStorage.getItem('session')
    const erkz = Number(watchAllFields.erkz_price)
    const response = await fetch(`/api/add_erkz?price=${erkz}&person=${person}`, {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
      }),
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Container>
      <ButtonGroup p='2' spacing='3' position='relative' left= "50%" transform="translate(-50%, 0)">
        <IconButton
          colorScheme='orange'
          icon={<Search2Icon />}
          onClick={modal3.onOpen}
        />
        <IconButton
          colorScheme='yellow'
          icon={<RepeatIcon />}
          onClick={() => window.location.reload()}
        />
        {user === 'admin' || user === 'disp' ? (
          <Button
            bg='#EEE568'
            onClick={() => {
              getErkz()
              modal2.onOpen()
            }}
          >
            ЕРКЦ
          </Button>
        ) : (
          null
        )}
      </ButtonGroup>
      <Modal isOpen={modal3.isOpen} size={'xs'} onClose={modal3.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Поиск цен</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid columns={1} spacingX='10px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Дата</FormLabel>
                  <Input
                    type='date'
                    placeholder=""
                    {...register("data_all")}
                  />
                </FormControl>
              </SimpleGrid>
              <ButtonGroup p='2' spacing='3' position='relative' left= "50%" transform="translate(-50%, 0)">
                <Button
                  type="submit"
                  onClick={modal3.onClose}
                  colorScheme='orange'
                  mt={3}
                >
                  Показать
                </Button>
                <Button
                  type="reset"
                  onClick={() => onFormReset()}
                  mt={3}
                >
                  Сбросить
                </Button>
              </ButtonGroup>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={modal2.isOpen} size={user === 'disp' ? 'xs' : 'sm' } onClose={modal2.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>Цена ЕРКЦ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {user === 'admin' ? (
              <form>
                <SimpleGrid columns={2} spacingX='10px' textAlign='center'>
                  <FormControl>
                    <FormLabel textAlign="center">Цена старая</FormLabel>
                    <Text fontSize='2xl' as='b'>{erkz_price}</Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel textAlign="center">Цена новая</FormLabel>
                    <Input
                      type='number'
                      onWheel={(event) => event.target.blur()}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={erkz_price}
                      {...register("erkz_price")}
                    />
                  </FormControl>
                </SimpleGrid>
                <ButtonGroup p='2' spacing='3' position='relative' left= "50%" transform="translate(-50%, 0)">
                  <Button
                    type="submit"
                    onClick={() => {
                      sendErkz()
                      modal2.onClose()
                    }}
                    colorScheme='orange'
                    mt={3}
                  >
                    Принять
                  </Button>
                  <Button
                    type="reset"
                    onClick={() => onFormReset()}
                    mt={3}
                  >
                    Сбросить
                  </Button>
                </ButtonGroup>
              </form>
            ) : (
              <form>
                <SimpleGrid columns={1} spacingX='10px' textAlign='center'  pb='20px'>
                  <FormControl>
                    <Text fontSize='2xl' as='b'>{erkz_price}</Text>
                  </FormControl>
                </SimpleGrid>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default SearchPrice;
