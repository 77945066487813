import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Tabss from "./components/Tabs";
import TabsMzl from "./components/TabsMzl";
import TabsUch from "./components/TabsUch";
import Login from "./components/Login";
import {RequireToken, RequireToken2, RequireToken3, RequireToken4} from './components/Auth'


function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <RequireToken>
              <Tabss />
            </RequireToken>
          }
        />
        <Route
          path="/uch"
          element={
            <RequireToken3>
              <TabsUch />
            </RequireToken3>
          }
        />
        <Route
          path="/mzl"
          element={
            <RequireToken2>
              <TabsMzl />
            </RequireToken2>
          }
        />
        <Route
          path="/disp"
          element={
            <RequireToken4>
              <TabsMzl />
            </RequireToken4>
          }
        />
      </Routes>
    </ChakraProvider>
  );
}

// способ получить доступ к одному элементу в DOM, с помощью уникального id. render - чтобы отрисовать элемент React
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
