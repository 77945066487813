import React from "react";
import { Box, Divider, Grid, Tabs, TabList, TabPanels, Tab, TabPanel, IconButton } from '@chakra-ui/react'
import TableMzl from "./TableMzl";
import {useNavigate} from "react-router-dom";
import { BiExit } from "react-icons/bi";

const TabsMzl = () => {
  const navigate = useNavigate();

  const signOut = () => {
    localStorage.removeItem('me3To_keN9');
    localStorage.removeItem('me3To_ke_disp');
    navigate("/");
  }

  return (
    <Tabs variant='soft-rounded' align='center'>
      <Grid templateColumns='repeat(3, 1fr)'>
        <Box />
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'gray.300' }} ml='15px'>МЗЛ</Tab>
        </TabList>
        <Box display='flex' alignItems='right' justifyContent='right' mr={1} mt={1}>
          <IconButton icon={<BiExit />} onClick={signOut} />
        </Box>
      </Grid>
      <TabPanels overflowX='scroll'>
        <TabPanel>
          <TableMzl />
        </TabPanel>
      </TabPanels>
      <Box bg='gray.200' w='100%' p={10} color='white'>
        <Divider />
      </Box>
    </Tabs>
    );
};

export default TabsMzl;