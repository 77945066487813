import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Container,
  Stack,
  Text,
  Checkbox,
  HStack,
  VStack,
  Box,
  SimpleGrid,
  Heading,
  Input,
  Spacer,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from 'react-hook-form';


const SignupForm = ({
  idstr,
  forma
}) => {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const toast = useToast();
  const [name, setName] = useState("");
  const [commer, setCommer] = useState("");
  const [pzy, setPzy] = useState("");
  const [vid_loma, setVid_loma] = useState("");
  const [pr_rekl_pos, setPr_rekl_pos] = useState("");
  const [interes_pos, setInteres_pos] = useState("");
  const [nalog_bank_pos, setNalog_bank_pos] = useState("");
  const [zasor_pos, setZasor_pos] = useState("");
  const [zak_zas_pos, setZak_zas_pos] = useState("");
  const [zak_zas, setZak_zas] = useState("");
  const [dop_zas_pos, setDop_zas_pos] = useState("");
  const [rekom_vip_pos, setRekom_vip_pos] = useState("");
  const [stavka_mlz_pos, setStavka_mlz_pos] = useState("");
  const [pr_vip_pos, setPr_vip_pos] = useState("");
  const [stavka_mlz_pos2, setStavka_mlz_pos2] = useState("");
  const [vip_doh_pos_2, setVip_doh_pos_2] = useState("");
  const [dohod_pos, setDohod_pos] = useState("");
  const [pr_rekl_pok, setPr_rekl_pok] = useState("");
  const [interes_pok, setInteres_pok] = useState("");
  const [rekom_vip_pok, setRekom_vip_pok] = useState("");
  const [stavka_mlz_pok, setStavka_mlz_pok] = useState("");
  const [pr_vip_pok, setPr_vip_pok] = useState("");
  const [stavka_mlz_pok2, setStavka_mlz_pok2] = useState("");
  const [vip_doh_pok_2, setVip_doh_pok_2] = useState("");
  const [dohod_pok, setDohod_pok] = useState("");
  const [dohod_pos_mzl1, setDohod_pos_mzl1] = useState("");
  const [pr_vip_pos1, setPr_vip_pos1] = useState("");
  const [dohod_pok_mzl1, setDohod_pok_mzl1] = useState("");
  const [pr_vip_pok1, setPr_vip_pok1] = useState("");
  const [stop_otgr, setStop_otgr] = useState("");
  const [s_mzl_only_card, setS_mzl_only_card] = useState("");
  const [s_mzl_not_card, setS_mzl_not_card] = useState("");
  const [s_vip_only_card, setS_vip_only_card] = useState("");
  const [s_vip_not_card, setS_vip_not_card] = useState("");

  useEffect(() => {  // хук позволяет добавлять побочные эффекты к заданному функциональному компоненту
    const fetchPrice = async () => {
      if (idstr.length === 0) {
       console.log('Wait')
      }
      else {  // получает данные
        const response = await fetch(`/api/get_str/${idstr}`, {method: "GET"})
        const lol = await response.json();
        setName(lol.data[0]['name'])
        setCommer(lol.data[0]['commer'])
        setPzy(lol.data[0]['pzy'])
        setVid_loma(lol.data[0]['vid_loma'])
        setPr_rekl_pos(lol.data[0]['pr_rekl_pos'])
        setInteres_pos(lol.data[0]['interes_pos'])
        setNalog_bank_pos(lol.data[0]['nalog_bank_pos'])
        setZasor_pos(lol.data[0]['zasor_pos'])
        setZak_zas_pos(lol.data[0]['zak_zas_pos'])
        setZak_zas(lol.data[0]['zak_zas'])
        setDop_zas_pos(lol.data[0]['dop_zas_pos'])
        setRekom_vip_pos(lol.data[0]['rekom_vip_pos'])
        setStavka_mlz_pos(lol.data[0]['stavka_mlz_pos'])
        setPr_vip_pos(lol.data[0]['pr_vip_pos'])
        setStavka_mlz_pos2(lol.data[0]['stavka_mlz_pos2'])
        setVip_doh_pos_2(lol.data[0]['vip_doh_pos_2'])
        setDohod_pos(lol.data[0]['dohod_pos'])
        setPr_rekl_pok(lol.data[0]['pr_rekl_pok'])
        setInteres_pok(lol.data[0]['interes_pok'])
        setRekom_vip_pok(lol.data[0]['rekom_vip_pok'])
        setStavka_mlz_pok(lol.data[0]['stavka_mlz_pok'])
        setPr_vip_pok(lol.data[0]['pr_vip_pok'])
        setStavka_mlz_pok2(lol.data[0]['stavka_mlz_pok2'])
        setVip_doh_pok_2(lol.data[0]['vip_doh_pok_2'])
        setDohod_pok(lol.data[0]['dohod_pok'])
        setDohod_pos_mzl1(lol.data[0]['dohod_pos_mzl'])
        setPr_vip_pos1(lol.data[0]['pr_vip_pos'])
        setDohod_pok_mzl1(lol.data[0]['dohod_pok_mzl'])
        setPr_vip_pok1(lol.data[0]['pr_vip_pok'])
        setStop_otgr(lol.data[0]['stop_otgr'])
        setS_mzl_only_card(lol.data[0]['s_mzl_only_card'])
        setS_mzl_not_card(lol.data[0]['s_mzl_not_card'])
        setS_vip_only_card(lol.data[0]['s_vip_only_card'])
        setS_vip_not_card(lol.data[0]['s_vip_not_card'])
      }
    };
    fetchPrice();
  }, [idstr, name, zak_zas]);

  const { register, handleSubmit, reset, watch, resetField } = useForm({
    mode: 'onChange',
    defaultValues: {  // устанавливает изначальные данные
      data_all: '',
      time: '',
      name: name,
      commer: commer,
      pzy: pzy,
      vid_loma: vid_loma,
      pr_rekl_pos: pr_rekl_pos,
      interes_pos: interes_pos,
      nalog_bank_pos: nalog_bank_pos,
      zasor_pos: zasor_pos,
      zak_zas_pos: zak_zas_pos,
      dop_zas_pos: dop_zas_pos,
      rekom_vip_pos: rekom_vip_pos,
      stavka_mlz_pos: stavka_mlz_pos,
      pr_vip_pos: pr_vip_pos,
      stavka_mlz_pos2: stavka_mlz_pos2,
      vip_doh_pos_2: vip_doh_pos_2,
      dohod_pos: dohod_pos,
      pr_rekl_pok: pr_rekl_pok,
      interes_pok: interes_pok,
      rekom_vip_pok: rekom_vip_pok,
      stavka_mlz_pok: stavka_mlz_pok,
      pr_vip_pok: pr_vip_pok,
      stavka_mlz_pok2: stavka_mlz_pok2,
      vip_doh_pok_2: vip_doh_pok_2,
      dohod_pok: dohod_pok,
      dohod_pos_mzl1: dohod_pos_mzl1,
      pr_vip_pos1: pr_vip_pos1,
      dohod_pok_mzl1: dohod_pok_mzl1,
      pr_vip_pok1: pr_vip_pok1,
      stop_otgr: stop_otgr ? stop_otgr : 0,
      s_mzl_only_card: s_mzl_only_card,
      s_mzl_not_card: s_mzl_not_card,
      s_vip_only_card: s_vip_only_card,
      s_vip_not_card: s_vip_not_card,
      zak_zas: zak_zas === 1 ? true : false,
      isPzy: false,
    },
  });

  useEffect(() => {  // сбрасывает изначальные и устанавливает полученные
    if (name) {
      resetField('pr_rekl_pos', { defaultValue: pr_rekl_pos });
      resetField('interes_pos', { defaultValue: interes_pos });
      resetField('nalog_bank_pos', { defaultValue: nalog_bank_pos });
      resetField('zasor_pos', { defaultValue: zasor_pos });
      resetField('zak_zas_pos', { defaultValue: zak_zas_pos });
      resetField('dop_zas_pos', { defaultValue: dop_zas_pos });
      resetField('rekom_vip_pos', { defaultValue: rekom_vip_pos });
      resetField('stavka_mlz_pos', { defaultValue: stavka_mlz_pos });
      resetField('pr_vip_pos', { defaultValue: pr_vip_pos });
      resetField('stavka_mlz_pos2', { defaultValue: stavka_mlz_pos2 });
      resetField('vip_doh_pos_2', { defaultValue: vip_doh_pos_2 });
      resetField('dohod_pos', { defaultValue: dohod_pos });
      resetField('pr_rekl_pok', { defaultValue: pr_rekl_pok });
      resetField('interes_pok', { defaultValue: interes_pok });
      resetField('rekom_vip_pok', { defaultValue: rekom_vip_pok });
      resetField('stavka_mlz_pok', { defaultValue: stavka_mlz_pok });
      resetField('pr_vip_pok', { defaultValue: pr_vip_pok });
      resetField('stavka_mlz_pok2', { defaultValue: stavka_mlz_pok2 });
      resetField('vip_doh_pok_2', { defaultValue: vip_doh_pok_2 });
      resetField('dohod_pok', { defaultValue: dohod_pok });
      resetField('stop_otgr', { defaultValue: stop_otgr });
      resetField('s_mzl_only_card', { defaultValue: s_mzl_only_card });
      resetField('s_mzl_not_card', { defaultValue: s_mzl_not_card });
      resetField('s_vip_only_card', { defaultValue: s_vip_only_card });
      resetField('s_vip_not_card', { defaultValue: s_vip_not_card });
      resetField('zak_zas', { defaultValue: zak_zas });
    }
  }, [resetField, pr_rekl_pos, interes_pos, nalog_bank_pos, zasor_pos, zak_zas_pos, dop_zas_pos, rekom_vip_pos,
  dohod_pok, dohod_pos, interes_pok, pr_rekl_pok, pr_vip_pok, pr_vip_pos, rekom_vip_pok, stavka_mlz_pok,
  stavka_mlz_pok2, stavka_mlz_pos, stavka_mlz_pos2, stop_otgr, s_mzl_only_card, s_mzl_not_card, s_vip_only_card,
  s_vip_not_card, zak_zas, name, pzy, commer, vid_loma, vip_doh_pos_2, vip_doh_pok_2]);

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const current = new Date();
  const today_date = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' +
                     ('0' + current.getDate()).slice(-2);

  var getDaysArray = function(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

  const pr_contr_pos = Number(watchAllFields.pr_rekl_pos)-Number(interes_pos)
  const dohod_pos_mzl = Math.round(pr_contr_pos-Number(dohod_pos))
  const pr_vip_pos0 = pr_contr_pos-Number(stavka_mlz_pos2)-Number(vip_doh_pos_2)

  const pr_contr_pok = Number(watchAllFields.pr_rekl_pok)-Number(interes_pok)
  const dohod_pok_mzl = Math.round(pr_contr_pok-Number(dohod_pok))
  const pr_vip_pok0 = pr_contr_pok-Number(stavka_mlz_pok2)-Number(vip_doh_pok_2)

  const onSubmit = async (data, e) => {
    const nalog_bank_pos_1 = Math.round(Number(data.pr_rekl_pos)/100*Number(nalog_bank_pos))
    const nalog_bank_pos_2 = Math.round(pr_contr_pos-nalog_bank_pos_1)
    const zasor_pos_1 = Math.round(Number(data.pr_rekl_pos)/100*Number(zasor_pos))
    const zasor_pos_2 = nalog_bank_pos_2-zasor_pos_1
    const dop_zas_pos_1 = Math.round(Number(data.pr_rekl_pos)/100*Number(dop_zas_pos))
    const dop_zas_pos_2 = zasor_pos_2-dop_zas_pos_1
    const zasor_razn = Number(zak_zas_pos)-Number(zasor_pos)
    const pr_vip_pos = pr_contr_pos-Number(stavka_mlz_pos2)-Number(vip_doh_pos_2)
    const r_vip_doh_pos_0 = Number(data.pr_rekl_pos)/100*zasor_razn
    const r_vip_doh_pos_1 = (Number(zasor_pos) === Number(zak_zas_pos))
      ? Math.round(nalog_bank_pos_2-Number(rekom_vip_pos))
      : Math.round(nalog_bank_pos_2-Number(rekom_vip_pos)+r_vip_doh_pos_0);
    const r_vip_doh_pos_2 = r_vip_doh_pos_1-Number(stavka_mlz_pos)

    const nalog_bank_pok = Number(nalog_bank_pos)
    const nalog_bank_pok_1 = Math.round(Number(data.pr_rekl_pok)/100*nalog_bank_pok)
    const nalog_bank_pok_2 = Math.round(pr_contr_pok-nalog_bank_pok_1)
    const zasor_pok = Number(zasor_pos)
    const zasor_pok_1 = Math.round(Number(data.pr_rekl_pok)/100*zasor_pok)
    const zasor_pok_2 = nalog_bank_pok_2-zasor_pok_1
    const dop_zas_pok = Number(dop_zas_pos)
    const dop_zas_pok_1 = Math.round(Number(data.pr_rekl_pok)/100*dop_zas_pok)
    const dop_zas_pok_2 = zasor_pok_2-dop_zas_pok_1
    const pr_vip_pok = pr_contr_pok-Number(stavka_mlz_pok2)-Number(vip_doh_pok_2)
    const r_vip_doh_pok_0 = Number(data.pr_rekl_pok)/100*zasor_razn
    const r_vip_doh_pok_1 = (Number(zasor_pos) === Number(zak_zas_pos))
      ? Math.round(nalog_bank_pok_2-Number(rekom_vip_pok))
      : Math.round(nalog_bank_pok_2-Number(rekom_vip_pok)+r_vip_doh_pok_0);
    const r_vip_doh_pok_2 = r_vip_doh_pok_1-Number(stavka_mlz_pok)

    const in_fact = (zak_zas === 1) ? 1 : 0
    const zak_zas_pos1 = (zak_zas === true) ? 6 : zak_zas_pos
    const time = data.time ? data.time : "00:00"
    const data_all = data.data_all +" "+ time

    var daylist = getDaysArray(data.data_all, new Date());
    daylist.map((v)=>v.toISOString().slice(0,10)).join("")

    let pzy_all;
    if (data.isPzy === true) {
      const response = await fetch(`/api/get_pzy/${name}/${commer}/` +
                `${vid_loma}`, {method: "GET"})
      const data = await response.json()  // извлечь полученные данные в виде JSON,
      pzy_all = data.data
    }
    else {
      pzy_all = [ { pzy: pzy } ]
    }

    for(const pzy of pzy_all){
        await fetch(`/api/add_changes?data_all=${data_all}&name=${name}&commer=${commer}` +
                      `&pzy=${pzy.pzy}&vid_loma=${vid_loma}&vip_pos_o=${pr_vip_pos1}&mzl_pos_o=${dohod_pos_mzl1}` +
                      `&vip_pok_o=${pr_vip_pok1}&mzl_pok_o=${dohod_pok_mzl1}&vip_pos_n=${pr_vip_pos}` +
                      `&mzl_pos_n=${dohod_pos_mzl}&vip_pok_n=${pr_vip_pok}&mzl_pok_n=${dohod_pok_mzl}`, {
            method: "POST",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
            }),
        });

        for(const day of daylist){
            const one_day = day.toISOString().slice(0,10)
            const time = (one_day === data.data_all && data.time ) ? data.time : "00:00"
            const data_al = one_day +" "+ time

            await fetch(`/api/add_new?data_all=${data_al}` +
                    `&name=${name}` +
                    `&commer=${commer}&pzy=${pzy.pzy}` +
                    `&vid_loma=${vid_loma}&pr_rekl_pos=${data.pr_rekl_pos}` +
                    `&interes_pos=${interes_pos}&pr_contr_pos=${pr_contr_pos}` +
                    `&nalog_bank_pos=${nalog_bank_pos}&nalog_bank_pos_2=${nalog_bank_pos_2}` +
                    `&zasor_pos=${zasor_pos}&zasor_pos_2=${zasor_pos_2}` +
                    `&zak_zas_pos=${zak_zas_pos1}&dop_zas_pos=${dop_zas_pos}` +
                    `&dop_zas_pos_2=${dop_zas_pos_2}&rekom_vip_pos=${rekom_vip_pos}` +
                    `&stavka_mlz_pos=${stavka_mlz_pos}&r_vip_doh_pos_2=${r_vip_doh_pos_2}` +
                    `&pr_vip_pos=${pr_vip_pos}&stavka_mlz_pos2=${stavka_mlz_pos2}` +
                    `&vip_doh_pos_2=${vip_doh_pos_2}&dohod_pos=${dohod_pos}` +
                    `&dohod_pos_mzl=${dohod_pos_mzl}&pr_rekl_pok=${data.pr_rekl_pok}` +
                    `&interes_pok=${interes_pok}&pr_contr_pok=${pr_contr_pok}` +
                    `&nalog_bank_pok_2=${nalog_bank_pok_2}&zasor_pok_2=${zasor_pok_2}&dop_zas_pok_2=${dop_zas_pok_2}` +
                    `&rekom_vip_pok=${rekom_vip_pok}&stavka_mlz_pok=${stavka_mlz_pok}` +
                    `&r_vip_doh_pok_2=${r_vip_doh_pok_2}&pr_vip_pok=${pr_vip_pok}` +
                    `&stavka_mlz_pok2=${stavka_mlz_pok2}&vip_doh_pok_2=${vip_doh_pok_2}` +
                    `&dohod_pok=${dohod_pok}&dohod_pok_mzl=${dohod_pok_mzl}&zak_zas=${in_fact}&stop_otgr=${0}` +
                    `&s_mzl_only_card=${s_mzl_only_card}&s_mzl_not_card=${s_mzl_not_card}` +
                    `&s_vip_only_card=${s_vip_only_card}&s_vip_not_card=${s_vip_not_card}`, {
              method: "POST",
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({
            }),
          });
      }
    }
    window.location.reload();
  };

  const sendPrice = async () => {  // window with alert that's all ok
    const response = await fetch(`/api/change_status`, {method: "GET"})
    const data = await response.json();
    if (data.data.length === 0) {
      return (
        toast({
          title: 'Не было обновлений цен',
          description: "Нет информации для публикации",
          status: 'info',
          duration: 9000,
          isClosable: true,
        })
      )
    }
    else {
      let name_text = "";
      const name_all = data.data
      for(const name of name_all){
        name_text += name.name + " "
      }
      return (
        toast({
          title: 'Готово',
          description: `Инфо об обновлении цен ${name_text} будет опубликовано`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      )
    }
  };

  const onFormReset = (data, e) => {
    reset();
  };

  return (
    <Container p={5} maxW="140ch">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={5} textAlign='center'>
          <Box bg='yellow.400' pb={2}>
            <Heading size='lg'>{name} {commer} {pzy} {vid_loma}</Heading>
          </Box>
          <SimpleGrid columns={4} spacingX='10px'>
            <Popover>
              <PopoverTrigger>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Дата</FormLabel>
                  <Input
                    type='date'
                    defaultValue=''
                    onKeyDown={handleEnter}
                    {...register("data_all")}
                  />
                </FormControl>
              </PopoverTrigger>
              { (watchAllFields.data_all > today_date) ? (
                <PopoverContent bg='red.200' width='100%'>
                  <PopoverCloseButton />
                  <PopoverHeader>
                    Не может быть<br />больше сегодняшней
                  </PopoverHeader>
                </PopoverContent>
              ):(
                null
              )}
            </Popover>
            <FormControl>
              <FormLabel textAlign="center">Время</FormLabel>
              <Input
                type='time'
                onKeyDown={handleEnter}
                {...register("time")}
              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} spacingX='10px'>
            <VStack pr={1} borderRight='0.5px solid lightgrey'>
              <Heading size='md' as='u'>ТРАНСПОРТ ПОСТАВЩИКА</Heading>
              <SimpleGrid columns={2} spacingX='10px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Цена рекламная</FormLabel>
                  <Input
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={pr_rekl_pos}
                    {...register("pr_rekl_pos")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Цена МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>{dohod_pos_mzl.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={2} spacingX='124px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_vip_pos0.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{stavka_mlz_pos2.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
            </VStack>
            <VStack>
              <Heading size='md' as='u'>ТРАНСПОРТ ПОКУПАТЕЛЯ</Heading>
              <SimpleGrid columns={2} spacingX='10px' spacingY='11px'>
                <FormControl isRequired>
                  <FormLabel textAlign="center">Цена рекламная</FormLabel>
                  <Input
                    type='number'
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={handleEnter}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={pr_rekl_pok}
                    {...register("pr_rekl_pok")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Цена МЗЛ</FormLabel>
                  <Text fontSize='2xl' as='b'>{dohod_pok_mzl.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={2} spacingX='124px'>
                <FormControl>
                  <FormLabel textAlign="center">Цена ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{pr_vip_pok0.toLocaleString('de-CH')}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel textAlign="center">Ставка ВИП</FormLabel>
                  <Text fontSize='2xl' as='b'>{stavka_mlz_pok2.toLocaleString('de-CH')}</Text>
                </FormControl>
              </SimpleGrid>
            </VStack>
          </SimpleGrid>
          <HStack>
            <Button
              type="reset"
              bg="tomato"
              py={5}
              px={30}
              color="white"
              fontWeight="bold"
              onClick={() => onFormReset()}
            >
              Сбросить
            </Button>
            <Button
              type="submit"
              bg="teal"
              py={5}
              px={30}
              color="white"
              fontWeight="bold"
              onClick={sendPrice}
            >
              Принять
            </Button>
            <Checkbox
              size='md'
              colorScheme='yellow'
              {...register("isPzy")}
            >
              Для всех ПЗУ {name} {vid_loma} ({commer})
            </Checkbox>
            <Spacer />
            <Link href="#startU">
              <Button onClick={forma.onClose} colorScheme='red' variant='outline'>
                Свернуть
              </Button>
            </Link>
          </HStack>
        </Stack>
      </form>
    </Container>
  );
};

export default SignupForm;