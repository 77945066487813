import { Navigate, useLocation } from "react-router-dom";


export const setToken = (token) =>{
// set token in localStorage
    localStorage.setItem('me3To_keN9rud', token)
}

export const fetchToken = (token) =>{
// fetch the token
    return localStorage.getItem('me3To_keN9rud')
}

export const setToken2 = (token) =>{
// set token in localStorage
    localStorage.setItem('me3To_keN9', token)
}

export const fetchToken2 = (token) =>{
// fetch the token
    return localStorage.getItem('me3To_keN9')
}

export const setToken3 = (token) =>{
// set token in localStorage
    localStorage.setItem('me3To_ke_uch', token)
}

export const fetchToken3 = (token) =>{
// fetch the token
    return localStorage.getItem('me3To_ke_uch')
}

export const setToken4 = (token) =>{
// set token in localStorage
    localStorage.setItem('me3To_ke_disp', token)
}

export const fetchToken4 = (token) =>{
// fetch the token
    return localStorage.getItem('me3To_ke_disp')
}

export function RequireToken({children}) {
    let auth = fetchToken()
    let location = useLocation();
    if (!auth) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
}

export function RequireToken2({children}) {
    let auth = fetchToken2()
    let location = useLocation();
    if (!auth) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
}

export function RequireToken3({children}) {
    let auth = fetchToken3()
    let location = useLocation();
    if (!auth) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
}

export function RequireToken4({children}) {
    let auth = fetchToken4()
    let location = useLocation();
    if (!auth) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
}